<template>
	<div>
		<!-- page-wrapper Start-->
		<div class="page-wrapper">
			<!-- error-404 start-->
			<div class="error-wrapper">
				<div class="container"><img class="img-100" src="@/assets/images/other-images/sad.png" alt="">

					<div class="col-md-8 offset-md-2">
						<p class="sub-content">An error has occurred, try again later.</p>
					</div>
					<div><button class="btn btn-success btn-lg text-uppercase"  @click=" fnGoBack() " >try again integration</button></div>
				</div>
			</div>
			<!-- error-404 end-->
		</div>
	</div>
</template>

<script>

export default {
	name: "IntegrationError",
	methods:{
		fnGoBack(){
			window.history.back();
		}
	},
}
</script>